<template>
  <div class="d-flex align-items-center min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <form @submit.prevent="register(model)">
                <h1>{{ $t('Регистрация') }}</h1>
                <p class="text-muted" hidden>Создайте Ваш аккаунт</p>
                <CInput
                  :placeholder="$t('Имя')"
                  autocomplete="name"
                  v-model.lazy="model.name"
                  required
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                  placeholder="Email"
                  autocomplete="email"
                  v-model.lazy="model.email"
                  required
                  type="email"
                >
                  <template #prepend-content><CIcon name="cil-envelope-closed"/></template>
                </CInput>
                <CInput
                  :placeholder="$t('Телефон')"
                  autocomplete="phone"
                  v-model.lazy="model.phone"
                  required
                  type="phone"
                  pattern="[\-\(\)+ 0-9]{10,25}"
                >
                  <template #prepend-content><CIcon name="cil-phone"/></template>
                </CInput>
                <CInput
                  :placeholder="$t('Пароль (не менее 6 символов)')"
                  type="password"
                  v-model.lazy="model.pass"
                  required
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>
                <CInput
                  :placeholder="$t('Повторите пароль')"
                  type="password"
                  v-model.lazy="model.pass2"
                  required
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>
                <CInput
                  :placeholder="$t('Промокод')"
                  type="text"
                  v-model.lazy="model.promo"
                  class="mb-4"
                >
                </CInput>

                <CInputCheckbox
                    :label="this.$t('Я даю согласие на обработку персональных данных, а также принимаю условия договоров оферты')
                    + ' https://evli.in/#/privacy, https://evli.in/#/license'"
                    :custom="true"
                    color="info"
                    v-model.lazy="model.privacy"
                />
                <br>
                <CButton color="success" block type="submit" :disabled="!model.privacy || !model.name || !model.email || !model.pass || !model.pass2">{{ $t('Зарегистрироваться') }}</CButton>
              </form>
            </CCardBody>
            <CCardFooter class="p-4">
              <CRow style="display: none">
                <CCol col="4">
                  <CButton block color="facebook">
                    Facebook
                  </CButton>
                </CCol>
                <CCol col="4">
                  <CButton block color="vk">
                    VK
                  </CButton>
                </CCol>
                <CCol col="4">
                  <CButton block color="youtube">
                    Google
                  </CButton>
                </CCol>
              </CRow>
              <CRow>
                <CCol col="6">
                  <br>
                  <p><router-link to="/login"><a>{{$t('Уже зарегистрированы ?')}}</a></router-link></p>
                  <notifications group="reg" />
                </CCol>
              </CRow>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import axios from '@/modules/axios'
export default {
  name: 'Register',
  data(){
    return {
      model: {
        name: '',
        email: '',
        phone: '',
        pass2: '',
        pass: '',
        code: '',
        promo: '',
        privacy: false
      }
    }
  },
  created() {
    if(localStorage.getItem('jwt')){
      this.$router.push('/calendars/list')
    }
  },
  methods: {
    register ({ name, email, phone, pass, pass2, code, promo }) {
      const data = JSON.stringify({
        name: name,
        phone: phone,
        pass: pass,
        pass2: pass2,
        email: email,
        code: code,
        promo: promo
      })
      axios.post('/auth/register', data, {
        headers: { 'content-type': 'application/json' }
      }).then((res) => {
        const user = res.data.data;
        this.$store.dispatch('setUser', user);
        localStorage.setItem('jwt', user.jwt)
        this.$router.push('/calendar/list')
      }).catch((error) => {
        this.$notify({ group: 'reg', text: error.response.data.message, type: 'error' })
      })
    }
  }
}
</script>
